import React from "react";

export default function Footer() {
  return (
    <div className="flex bg-black flex-col justify-center pt-5 pb-5 sm:flex-row">
      <p className="text-sm text-center text-white">
        Copyright ©{new Date().getFullYear()}{" "}
        <a
          target="_blank"
          href="https://aeroaegis.com/"
          className="text-blue-700 font-semibold"
        >
          Aeroaegis
        </a>
      </p>
    </div>
  );
}
