import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Modal({ isOpen, closeModal }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setShowSpinner(true);

    try {
      const response = await axios.post(
        "http://localhost/aeroaegis/contact.php",
        formData
      );
      if (response.status === 200) {
        setFormData({
          user_name: "",
          user_email: "",
          user_message: "",
        });
        closeModal();
      } else {
        console.error("Unsuccessful response:", response.status, response.data);
      }
    } catch (error) {
      console.error("error", error);
      if (error.response) {
        console.error("Server Error:", error.response.data);
      } else if (error.request) {
        console.error("No response received from server.");
      } else {
        console.error("Error during request setup:", error.message);
      }
    } finally {
      setIsSubmitting(false);
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-50 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="transition-opacity bg-gray-900 bg-opacity-40 fixed inset-0"
              aria-hidden="true"
              onClick={closeModal}
            ></div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:"
              aria-hidden="true"
            >
              ​
            </span>

            <div className="inline-block z-10 p-5 overflow-hidden text-left align-bottom transition-all transform bg-black pattern rounded-lg shadow-2xl lg:p-16 sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
              <div>
                <div className="mt-3 text-left sm:mt-5">
                  <h2
                    className="text-3xl font-semibold tracking-widest bg-gradient-to-r from-blue-600 via-[#5729a5] to-indigo-400 inline-block text-transparent bg-clip-text
                uppercase"
                  >
                    Get in Touch with Us
                  </h2>
                  <hr className="ml-10 mb-10 mr-10 md:ml-60 md:mr-60 xl:ml-[30px] xl:mr-[90px] mt-4 border-2 border-[#452283]" />
                  <h1 className="mb-8 text-lg font-semibold leading-none tracking-tighter text-neutral-300">
                    Drop us a note and we will get back to you within 24 hours.
                  </h1>
                  <form className="mt-4" method="post" onSubmit={handleSubmit}>
                    <div className=" md:grid lg:grid lg:grid-cols-2 gap-3">
                      <div className="mb-4">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-300"
                        >
                          Your Name
                        </label>
                        <input
                          type="text"
                          name="user_name"
                          value={formData.user_name}
                          onChange={handleChange}
                          className="mt-1 p-2 w-full border rounded-md"
                          placeholder="User Name"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-300"
                        >
                          Your Email
                        </label>
                        <input
                          type="email"
                          name="user_email"
                          value={formData.user_email}
                          onChange={handleChange}
                          className="mt-1 p-2 w-full border rounded-md"
                          placeholder="User Email"
                          required
                        />
                      </div>
                      <div className="col-span-2 mb-4">
                        <label
                          htmlFor="message"
                          className="block text-sm font-medium text-gray-300"
                        >
                          Message
                        </label>
                        <textarea
                          name="user_message"
                          value={formData.user_message}
                          onChange={handleChange}
                          className="mt-1 p-2 w-full border rounded-md"
                          placeholder="Leave a message here"
                          style={{ height: "120px" }}
                          required
                        ></textarea>
                      </div>
                      <div className="col-span-2"></div>
                    </div>
                    <div className="col-span-2">
                      <button
                        className="btn bg-[#252f80] text-white hover:bg-[#252f90] rounded-pill py-3 px-10 w-full"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {showSpinner ? (
                          <div className="h-5 w-5 animate-spin rounded-full border-t-4 border-white mx-auto" />
                        ) : (
                          "Send Message"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
