import React from "react";
import rocket_img from "../../../src/Assests/img/Untitled_design-removebg-preview.png";
import one_svg from "../../Assests/img/number-1-circle-svgrepo-com.svg";
import two_svg from "../../Assests/img/number-2-circle-svgrepo-com.svg";
import three_svg from "../../Assests/img/number-3-circle-svgrepo-com.svg";
import four_svg from "../../Assests/img/number-4-circle-svgrepo-com.svg";
import five_svg from "../../Assests/img/number-5-circle-svgrepo-com.svg";
import six_svg from "../../Assests/img/number-6-circle-svgrepo-com.svg";

export default function Timeline() {
  return (
    <section>
      <div class="bg-black text-white py-4 xl:px-5">
        <div class="container mx-auto flex flex-col items-start md:flex-row">
          <div class="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8">
            <p class="uppercase tracking-loose inline-block text-white font-semibold pb-2 text-3xl md:text-3xl lg:text-2xl">
              Setting Up
            </p>
            <p class="text-4xl md:text-4xl lg:text-5xl pb-2 mb-2 inline-block">
              Its Time To Get Started
            </p>
            <p class=" pb-2 text-sm md:text-base lg:text-base text-gray-200 mb-4">
              Here’s your guide to Setting Up Your SmartChaosLab Account. Go
              through all the steps to know the exact process.
            </p>
            <a class="bg-transparent mr-auto hover:bg-[#7136d7] text-[#49238b] hover:text-black rounded shadow hover:shadow-lg py-2 px-4 border border-[#49238b] hover:border-transparent font-semibold">
              Explore Now -
            </a>
          </div>
          <div class="ml-0 md:ml-12 lg:w-2/3 sticky">
            <div class="container mx-auto w-full h-full">
              <div class="relative wrap overflow-hidden p-10 h-full">
                <div
                  className="border-2-2 border-yellow-555 absolute h-full border"
                  style={{
                    right: "50%",
                    border: "2px solid #5729a5",
                    borderRadius: "1%",
                  }}
                ></div>
                <div
                  className="border-2-2 border-yellow-555 absolute h-full border"
                  style={{
                    left: "50%",
                    border: "2px solid #5729a5",
                    borderRadius: "1%",
                  }}
                ></div>
                <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                  <div class="order-1 w-5/12"></div>
                  <div class="order-1 w-5/12 px-1 py-4 text-right md:text-right lg:text-right">
                    <p class="mb-3 text-base ml-[65px] w-8 md:w-8 md:ml-[75px] lg:w-10 lg:ml-[190px] xl:ml-[320px]">
                      <img src={one_svg} />
                    </p>
                    <h4 class="mb-3 font-bold text-sm md:text-sm lg:text-xl xl:text-2xl">
                      Setting Up Your SmartChaosLab Account
                    </h4>
                    <p class="text-sm md:text-sm lg:text-xl xl:text-xl leading-snug text-gray-300 text-opacity-100">
                      Begin your journey by signing up. Click the "Sign Up"
                      button to be directed to the registration page. Complete
                      the registration, and after verifying your account, you
                      can log in. This will take you to the central dashboard,
                      the heart of SmartChaosLab.
                    </p>
                  </div>
                </div>
                <div class="mb-8 flex justify-between items-center w-full right-timeline">
                  <div class="order-1 w-5/12"></div>
                  <div class="order-1  w-5/12 px-1 py-4 text-left">
                    <p class="mb-3 text-base w-8 md:w-8 lg:w-10 lg:ml-[-5px]">
                      <img src={two_svg} />
                    </p>
                    <h4 class="mb-3 font-bold text-xs md:text-sm lg:text-xl xl:text-2xl">
                      Navigating the Dashboard
                    </h4>
                    <p class="text-sm md:text-sm lg:text-xl xl:text-xl leading-snug text-gray-300 text-opacity-100">
                      The dashboard is designed for intuitive navigation. Here,
                      you'll find all the tools and features you need for your
                      chaos experiments. It provides an overview of ongoing
                      experiments, system insights, and shortcuts to various
                      functionalities. Familiarize yourself with the dashboard
                      layout to optimize your chaos engineering tasks.
                    </p>
                  </div>
                </div>
                <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                  <div class="order-1 w-5/12"></div>
                  <div class="order-1 w-5/12 px-1 py-4 text-right">
                    <p class="mb-3 text-base ml-[65px] w-8 md:w-8 md:ml-[75px] lg:w-10 lg:ml-[190px] xl:ml-[320px]">
                      <img src={three_svg} />
                    </p>
                    <h4 class="mb-3 font-bold text-xs md:text-sm lg:text-xl xl:text-2xl">
                      Creating Your First Chaos Experiment
                    </h4>
                    <p class="text-sm md:text-sm lg:text-xl xl:text-xl leading-snug text-gray-300 text-opacity-100">
                      Move to the "Experiments" tab from your dashboard. Click
                      on "New Experiment." The platform will guide you through
                      the process, prompting you to input relevant details and
                      the type of chaos you wish to implement. Follow the
                      on-screen instructions to set the parameters for your
                      chaos test.
                    </p>
                  </div>
                </div>

                <div class="mb-8 flex justify-between items-center w-full right-timeline">
                  <div class="order-1 w-5/12"></div>

                  <div class="order-1  w-5/12 px-1 py-4">
                    <p class="mb-3 text-base w-8 md:w-8 lg:w-10 lg:ml-[-5px]">
                      <img src={four_svg} />
                    </p>
                    <h4 class="mb-3 font-bold text-xs md:text-sm lg:text-xl text-left xl:text-2xl">
                      Launching the Experiment
                    </h4>
                    <p class="text-sm md:text-sm lg:text-xl xl:text-xl leading-snug text-gray-300 text-opacity-100">
                      After setting up your experiment, it's time to unleash the
                      chaos! Click the "Launch" or "Start Experiment" button.
                      SmartChaosLab will begin introducing the pre-defined
                      disruptions into your system, allowing you to observe its
                      behavior under these conditions.
                    </p>
                  </div>
                </div>

                <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                  <div class="order-1 w-5/12"></div>
                  <div class="order-1 w-5/12 px-1 py-4 text-right">
                    <p class="mb-3 text-base ml-[65px] w-8 md:w-8 md:ml-[75px] lg:w-10 lg:ml-[190px] xl:ml-[320px]">
                      <img src={five_svg} />
                    </p>
                    <h4 class="mb-3 font-bold text-xs md:text-sm lg:text-xl xl:text-2xl">
                      Real-time Monitoring and Analysis
                    </h4>
                    <p class="text-sm md:text-sm lg:text-xl xl:text-xl leading-snug text-gray-300 text-opacity-100">
                      One of SmartChaosLab's standout features is its real-time
                      monitoring. While your experiment runs, switch to the
                      "Monitoring" or "Live Analysis" tab on your dashboard.
                      Here, you'll see how your system reacts, with live metrics
                      and visual representations of system behavior.
                    </p>
                  </div>
                </div>

                <div class="mb-8 flex justify-between items-center w-full right-timeline">
                  <div class="order-1 w-5/12"></div>

                  <div class="order-1  w-5/12 px-1 py-4">
                    <p class="mb-3 text-base w-8 md:w-8 lg:w-10 lg:ml-[-5px]">
                      <img src={six_svg} />
                    </p>
                    <h4 class="mb-3 font-bold text-xs md:text-sm text-left lg:text-xl xl:text-2xl">
                      Accessing Reports and Gathering Insights
                    </h4>
                    <p class="text-sm md:text-sm lg:text-xl xl:text-xl leading-snug text-gray-300 text-opacity-100">
                      Once your experiment concludes, head over to the "Reports"
                      section. SmartChaosLab provides comprehensive analysis on
                      how your system fared during the chaos test. Dive deep
                      into these insights, understanding weaknesses and
                      strengths, and plan further experiments or necessary
                      system enhancements based on the feedback.
                    </p>
                  </div>
                </div>
              </div>
              <img
                class="mx-auto -mt-36 md:-mt-36 pt-24 md:pt-24 lg:pt-24"
                src={rocket_img}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
