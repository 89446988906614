import React from "react";
import HeroSection from "./HeroSection";
import TimeLine from "../GetStarted";
import Behind from "../BehindTheScene";
import Special from "../WhyIsItSpecial";
import Importance from "../ImportanceOfChaosEng";
import Benefits from "../BenefitsOfSmartChaos";
import SmartChaosWorks from "../SmartChaosWorking";
import VideoSection from "../VideoSection/VideoSection";

export default function Index() {
  return (
    <div>
      <HeroSection />
      <Special />
      <SmartChaosWorks />
      <Importance />
      <Benefits />
      <Behind />
      <TimeLine />
      <VideoSection />
    </div>
  );
}
