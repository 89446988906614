import React from "react";

export default function VideoSection() {
  return (
    <div style={{ position: "relative" }}>
      <iframe
        width="100%"
        className="h-[185px] md:h-[435px] lg:h-[855px]"
        src="https://www.youtube.com/embed/T1DBsUy7lnE?si=4w5dI92jZBX6Gy3I"
        title="Smart Chaos Lab"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
    </div>
  );
}
