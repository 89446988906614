import React from 'react'
import CoreFeature from './CoreFeature'
import AdvanceFeatures from './AdvanceFeatures'

export default function index() {
  return (
    <div>
        <CoreFeature/>
        <AdvanceFeatures/>
    </div>
  )
}
