import React from 'react'
import BenefitsOfChaos from './BenefitsOfChaos'

export default function index() {
  return (
    <div>
        <BenefitsOfChaos/>
    </div>
  )
}
