import React, { useEffect } from "react";
import HeaderComponent from "./Pages/header";
import Footer from "./Pages/Footer";
import HomePage from "./Pages/Homepage";
import KeyOfferings from "./Pages/KeyOfferings";
import Pricings from "./Pages/Pricing";
import FAQ from "./Pages/FAQ";
import Modal from "./Pages/Modal";
import Contact from "./Pages/ContactUs";
import { Route, Routes, useLocation } from "react-router-dom";

const App = () => {
  const location = useLocation();
  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  // Scroll Top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <HeaderComponent />
      <Routes>
        <Route>
          <Route path="/" Component={HomePage} />
          <Route path="/key-offerings" Component={KeyOfferings} />
          <Route path="/pricing" Component={Pricings} />
          <Route path="/faq" Component={FAQ} />
          <Route path="/contact" Component={Contact} />
          <Route path="/contact-us" Component={Modal} />
        </Route>
      </Routes>
      <div className="fixed bottom-8 right-8 z-50">
        <button
          onClick={scrollToTop}
          className="bg-[#black] text-white rounded-full p-3 hover:bg-[#612eb9] focus:outline-none focus:shadow-outline"
          aria-label="Scroll to top"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            ></path>
          </svg>
        </button>
      </div>
      <Footer />
    </>
  );
};

export default App;
