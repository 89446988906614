import React from "react";

export default function ImportanceChaos() {
  return (
    <div class="relative group">
      <img
        class="object-cover w-full h-full sm:h-96"
        src="https://images.pexels.com/photos/5125162/pexels-photo-5125162.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        alt=""
      />
      <div class="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full  text-center transform -translate-x-1/2 -translate-y-1/2 text-white  font-bold transition duration-300 opacity-100 group-hover:opacity-0  hover:bg-opacity-100">
        <p className="mt-[100px] text-lg md:text-4xl lg:text-6xl my-20 md:my-36 lg:my-36">
          Importance of Chaos Engineering
        </p>
      </div>
      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 transition duration-700 -translate-y-1/2  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
        <p className="absolute text-center text-[7.8px] md:text-sm lg:text-xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
          In an increasingly interconnected and technology-dependent world, the
          consequences of system failures can be significant. Organizations face
          a growing need to proactively identify and mitigate vulnerabilities
          within their digital infrastructure. This is where chaos engineering
          steps in a discipline that advocates for the deliberate introduction
          of controlled chaos into systems to uncover weaknesses, test
          resilience, and enhance overall performance.
          <br />
          <br />
          <span className="">
            Chaos engineering is not merely a practice; it is a strategic
            approach to system management hat aligns with the dynamic nature of
            modern technology. It is a proactive measure that empowers
            organizations to be well-prepared for unexpected challenges and
            disruptions.
          </span>
        </p>
      </div>
    </div>
  );
}
