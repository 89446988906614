import React, { useState } from "react";
import { motion } from "framer-motion";
import Modal from "../Modal/Modal";
import fly_svg from "../../Assests/img/telegram-svgrepo-com.svg";
import air_svg from "../../Assests/img/flight-svgrepo-com.svg";
import settings_svg from "../../Assests/img/settings(1)-svgrepo-com.svg";
import rocket_svg from "../../Assests/img/rocket-svgrepo-com.svg";
import circle_svg from "../../Assests/img/check-circle-svgrepo-com.svg";
import circle_cyan_svg from "../../Assests/img/check-cyan-circle-svgrepo-com.svg";
import "./index.css";

const TOGGLE_CLASSES =
  "text-sm font-medium flex items-center gap-2 px-3 md:pl-3 md:pr-3.5 py-3 md:py-1.5 transition-colors relative z-10";

const SliderToggle = ({ selected, setSelected }) => {
  return (
    <div className="relative flex w-fit items-center rounded-full">
      <button
        className={`${TOGGLE_CLASSES} ${
          selected === "monthly" ? "text-zinc-950" : "text-zinc-950"
        }`}
        onClick={() => {
          setSelected("monthly");
        }}
      >
        <div className="relative z-10 text-lg md:text-sm" />
        <span className="relative z-10 text-white">Monthly</span>
      </button>
      <button
        className={`${TOGGLE_CLASSES} ${
          selected === "annually" ? "text-zinc-950" : "text-zinc-950"
        }`}
        onClick={() => {
          setSelected("annually");
        }}
      >
        <div className="relative z-10 text-lg md:text-sm" />
        <span className="relative z-10 text-white">Annually</span>
      </button>
      <div
        className={`absolute inset-0 z-0 flex ${
          selected === "annually" ? "justify-end" : "justify-start"
        }`}
      >
        <motion.span
          layout
          transition={{ type: "spring", damping: 15, stiffness: 250 }}
          className="h-full w-1/2 rounded-full bg-gradient-to-r from-[#49238b] to-[#5729a5]"
        />
      </div>
    </div>
  );
};

export default function Pricing() {
  const [selected, setSelected] = useState("monthly");
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const plans = [
    {
      title: "BASIC",
      image: fly_svg,
      features: [
        "30 Day Free Trial.",
        "Access to Basic Chaos Experiments.",
        "AI Driven Insights.",
      ],
      price: {
        monthly: 458.33,
        annually: 4950,
      },
    },
    {
      title: "PROFESSIONAL",
      image: air_svg,
      features: [
        "Access to Standard Chaos Experiments",
        "Basic Game Theory Integration",
        "AI-Driven Recommendations",
        "Real-Time Monitoring",
      ],
      price: {
        monthly: 1125,
        annually: 12150,
      },
    },
    {
      title: "ENTERPRISE",
      image: rocket_svg,
      features: [
        "Unlimited Chaos Experiments",
        "Dedicated Onboarding and Training",
        "Full API Access",
        "Advanced Security Tools and More",
      ],
      price: {
        monthly: 2333.33,
        annually: 25200,
      },
    },
    {
      title: "CUSTOM",
      image: settings_svg,
      features: [
        "Tailored Feature Set",
        "R & D Support",
        "Enterprise-Level Security",
        "Direct Input into Product Development",
      ],
      price: {
        monthly: 4583.33,
        annually: 49500,
      },
    },
  ];

  const getFormattedPrice = (price) => {
    return selected === "annually" && Number.isInteger(price)
      ? `${price.toLocaleString()}`
      : `${price.toFixed(2)}`;
  };

  return (
    <section className="relative overflow-hidden bg-black">
      <div className="text-center">
        <h1 className="text-white font-semibold text-4xl md:text-5xl xl:text-5xl pb-4 xl:pb-5 pt-10">
          Pricing
        </h1>
        <hr className="ml-28 mr-28 md:ml-60 md:mr-60 xl:ml-[650px] xl:mr-[650px] border-2 border-[#452283]" />
        <p className="mt-4 mb-5 text-lg text-gray-300">
          Every plan includes every feature, and can scale as your team does.
        </p>
      </div>

      <div
        className={`grid lg:pt-5 place-content-center px-4 transition-colors ${
          selected === "monthly" ? "to-zinc-950" : "to-zinc-950"
        }`}
      >
        <SliderToggle selected={selected} setSelected={setSelected} />
      </div>
      <div className="relative flex flex-col items-center justify-center px-8 py-12 mx-auto md:px-12 lg:px-16 xl:px-36 lg:py-10">
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-4 gap-y-12">
          {plans.map((plan, index) => (
            <div key={index} className="card-container flex flex-col h-full">
              <div className="px-4">
                <div className="flex items-center justify-center gap-3 text-white">
                  <div className="w-16 md:w-16 lg:w-12">
                    <img src={plan.image} />
                  </div>
                  <p className="font-mono text-2xl md:text-3xl lg:text-xl font-medium">
                    {plan.title}
                  </p>
                </div>
              </div>
              <div className="flex-grow p-8 mt-4 shadow-2xl shadow-black rounded-xl bg-zinc-900 backdrop-blur-xl border-white/5 ring-1 ring-white/10 flex flex-col justify-between">
                <p className="mb-14 font-mono text-center font-bold tracking-tighter">
                  <span className="text-white text-4xl md:text-4xl lg:text-4xl xl:text-5xl">
                    ${getFormattedPrice(plan.price[selected])}
                  </span>
                </p>
                <ul
                  className="flex flex-col text-sm text-gray-300 gap-y-3"
                  role="list"
                >
                  {plan.features.map((feature, index) => (
                    <li
                      key={index}
                      className="flex flex-row items-center gap-2"
                    >
                      <img className="w-8 md:w-8 lg:w-8" src={circle_svg} />
                      <span className="text-[18px]">{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="flex mt-6 lg:mt-[52px]">
                  <a
                    onClick={openModal}
                    className="font-semibold rounded-lg px-4 py-2 text-sm transition-all flex items-center justify-center text-white bg-gradient-to-b hover:from-blue-600 via-[#5729a5] to-indigo-400 text-transparent bg-clip-text h-10 ring-1 ring-inset ring-white/10 w-full"
                    href="#_"
                    aria-describedby={`tier-${plan.title.toLowerCase()}`}
                  >
                    Subscribe
                    <img
                      className="w-8 ml-2 md:w-8 md:ml-2 lg:w-5 lg:ml-2"
                      src={circle_svg}
                    />
                  </a>
                </div>
              </div>
              {/* Modal */}
              <Modal isOpen={isModalOpen} closeModal={closeModal} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
