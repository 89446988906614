import React from "react";
import check_svg from "../../Assests/img/check-circle-svgrepo-com.svg";
import Auto_scaling from "../../Assests/img/KeyOfferings/Advance Features/concrete-room-with-triangle-portal-illuminated-by-blue-orange-neon-light-created-using-generative-ai.jpg";
import Chaos_exp_img from "../../Assests/img/KeyOfferings/Advance Features/man-using-digital-tablet-psd-mockup-smart-technology.jpg";
import CICD_img from "../../Assests/img/KeyOfferings/Advance Features/steel-pipelines-cables-plant.jpg";
import Chaos_analysis_img from "../../Assests/img/KeyOfferings/Advance Features/startups-business-teamwork-meeting-analyse-marketing-data.jpg";
import Collaborative_img from "../../Assests/img/KeyOfferings/Advance Features/handshake-businessmen.jpg";
import Development_img from "../../Assests/img/KeyOfferings/Advance Features/programming-background-with-person-working-with-codes-computer.jpg";
import Security_img from "../../Assests/img/KeyOfferings/Advance Features/back-view-dangerous-team-hackers-working-new-malware.jpg";
import Testing_img from "../../Assests/img/KeyOfferings/Advance Features/rear-view-programmer-working-all-night-long.jpg";
import Report_img from "../../Assests/img/KeyOfferings/Advance Features/discussing-charts.jpg";

const FeatureItem = ({ id, title, advantages, image }) => {
  return (
    <div
      className={`flex flex-col md:items-center gap-10 lg:gap-14 ${
        id % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
      }`}
    >
      <div className="md:w-[48%] lg:w-[48%] xl:w-[48%] md:py-6 xl:py-12 space-y-8">
        <div className="space-y-6">
          <h2 className="mt-4 text-xl md:text-2xl lg:text-2xl xl:text-4xl font-semibold text-white lg:text-center">
            {title}
          </h2>
        </div>
        <div className="text-sm md:text-sm lg:text-lg xl:text-xl space-y-5 children:flex children:items-start children:gap-4 children:text-gray-600 text-gray-200">
          <ul role="list">
            {advantages.map((advantage) => (
              <li key={advantage.id} className="flex pb-5">
                <img className="w-8 md:w-8 lg:w-8 mr-4" src={check_svg} />
                {advantage.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex-1 relative lg:mt-10 p-1 overflow-hidden">
        <img className="lg:w-100" src={image} alt="illustration" />
      </div>
    </div>
  );
};

const features = [
  {
    id: 1,
    title: "Auto-Scaling Chaos",
    icon: "cross",
    advantages: [
      {
        id: 1,
        text: "Automatically adjust the intensity of chaos experiments based on system conditions and user-defined parameters for efficient testing.",
      },
    ],
    image: Auto_scaling,
  },
  {
    id: 2,
    title: "Multi-Cloud Chaos Testing",
    icon: "cross",
    advantages: [
      {
        id: 2,
        text: "Extend chaos experimentation across multiple cloud providers simultaneously, ensuring resilience in complex, multi-cloud environments.",
      },
    ],
    image:
      "https://img.freepik.com/free-photo/young-adult-caucasian-male-engineer-working-with-technology-indoors-night-generated-by-ai_188544-56125.jpg?t=st=1706008697~exp=1706012297~hmac=dbc11e432983edef7c9ce135a4681c4e34caa49f62f616dadca884caca7e53f5&w=1060",
  },
  {
    id: 3,
    title: "Chaos Experiment Simulation",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "Run simulations of chaos experiments before applying them to production, allowing for risk assessment and mitigation.",
      },
    ],
    image: Chaos_exp_img,
  },
  {
    id: 4,
    title: "Integration with CI/CD Pipelines",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "Seamlessly integrate SmartChaosLab with your CI/CD pipelines for continuous chaos testing and deployment.",
      },
    ],
    image: CICD_img,
  },
  {
    id: 5,
    title: "Predictive Chaos Analytics",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "Leverage machine learning to predict potential system weaknesses and proactively address them before they become critical issues.",
      },
    ],
    image: Chaos_analysis_img,
  },
  {
    id: 6,
    title: "Collaborative Chaos Engineering",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "Foster collaboration within your team by enabling multiple users to work on and analyze chaos experiments collaboratively.",
      },
    ],
    image: Collaborative_img,
  },
  {
    id: 7,
    title: "Custom Chaos Experiment Development",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "Create and deploy custom chaos experiments tailored to your organization's unique needs and infrastructure.",
      },
    ],
    image: Development_img,
  },
  {
    id: 8,
    title: "Security Vulnerability Detection",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "Expand chaos engineering to include security vulnerabilities, allowing you to identify and patch security weaknesses effectively.",
      },
    ],
    image: Security_img,
  },
  {
    id: 9,
    title: "Scenario-Based Chaos Testing",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "Define complex scenarios and test their impact on system resilience, ensuring preparedness for real-world challenges.",
      },
    ],
    image: Testing_img,
  },
  {
    id: 10,
    title: "Advanced Reporting and Analytics",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "Dive deeper into your chaos experiment data with advanced reporting and analytics tools, allowing for comprehensive insights and trend analysis.",
      },
    ],
    image: Report_img,
  },
];

export default function AdvanceFeatures() {
  return (
    <section className="py-10 bg-black">
      <div className="max-w-7xl mx-auto px-5 sm:px-10 md:px-12 lg:px-5">
        <div className="flex flex-col">
          <div className="flex flex-col justify-center text-center mb-10  mx-auto md:max-w-2xl space-y-5">
            <h1 className="text-3xl font-semibold inline-block text-white md:text-4xl xl:text-5xl leading-tight uppercase ">
              Advance Features
            </h1>
            <hr className="ml-10 mr-10 md:ml-60 md:mr-60 xl:ml-[50px] xl:mr-[50px] mt-4 border-2 border-[#452283]" />
          </div>
          {/* <hr className='ml-10 mr-10 md:ml-60 md:mr-60 lg:ml-96 lg:mr-96 border-2 border-[#452283]'/> */}
          <div className="grid divide-y divide-gray-300 gap-8 children:py-5 first:pt-0 last:pb-0">
            {features.map((feature) => (
              <FeatureItem key={feature.id} {...feature} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
