import React, { useEffect, useState } from "react";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import ContactImg from "../../../src/Assests/img/Contact us-rafiki.png";
import { IoMailOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { IoLocationOutline } from "react-icons/io5";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
const API_PATH = "https://aeroaegis.com/contact.php";

const Contact = () => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [captchaError, setCaptchaError] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  useEffect(() => {
    loadCaptchaEnginge(6, "#000000", "#FFFFFF");
    const interval = setInterval(() => {
      loadCaptchaEnginge(6, "#000000", "#FFFFFF");
    }, 60000);
    // Clear the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let userCaptchaValue = e.target.captcha.value;
    // Validate the captcha
    if (!validateCaptcha(userCaptchaValue)) {
      setCaptchaError("Captcha Does Not Match");
      return;
    }
    // Reset the captcha value
    setCaptchaValue("");
    setCaptchaError("");
    setIsSubmitting(true);
    setShowSpinner(true);
    setIsOverlayVisible(true); // Show overlay

    try {
      const response = await axios.post(API_PATH, formData);
      if (response.status === 200) {
        setIsSuccessModalOpen(true);
        setFormData({
          user_name: "",
          user_email: "",
          user_message: "",
        });
        loadCaptchaEnginge(6, "#000000", "#FFFFFF");
      } else {
        console.error("Unsuccessful response:", response.status, response.data);
      }
    } catch (error) {
      console.error("error", error);
      if (error.response) {
        console.error("Server Error:", error.response.data);
      } else if (error.request) {
        console.error("No response received from server.");
      } else {
        console.error("Error during request setup:", error.message);
      }
      setIsSubmitting(false);
      setShowSpinner(false);
    } finally {
      setIsSubmitting(false);
      setShowSpinner(false);
      setIsOverlayVisible(false);
    }
  };

  const closeModal = () => {
    setIsSuccessModalOpen(false);
  };

  useEffect(() => {
    if (isSuccessModalOpen) {
      const timer = setTimeout(() => {
        setShowSpinner(false);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isSuccessModalOpen]);
  return (
    <div>
      <div className="bg-black">
        <div className="container mx-auto md:px-6">
          <section className="py-20 flex items-center justify-center">
            <div className="container p-4">
              <div className="max-w-xs mx-auto text-center">
                <span className="text-base lg:text-2xl font-semibold text-gray-300">
                  Contact Us
                </span>
                <h3 className="text-3xl font-bold text-white mt-2">
                  Feel{" "}
                  <span className="text-blue-500">
                    Free To Reach Us <span className="text-white">Anytime</span>
                  </span>
                </h3>
              </div>

              <div className="max-w-5xl mx-auto mt-10">
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                  <div className="p-5 rounded-xl border border-gray-200 bg-white shadow-lg">
                    <div className="flex items-center gap-5">
                      <div className="h-14 w-14 flex items-center justify-center rounded-full text-white bg-blue-500">
                        <IoMailOutline className="text-3xl" />
                      </div>
                      <div>
                        <span className="text-xl font-semibold text-blue-500">
                          Drop a line
                        </span>
                        <h3 className="text-2xl font-bold text-gray-900">
                          Mail Us
                        </h3>
                      </div>
                    </div>
                    <p className="text-base text-center font-semibold text-gray-500 mt-5">
                      info@aeroaegis.com
                    </p>
                  </div>

                  <div className="p-5 rounded-xl border border-gray-200 bg-white shadow-lg">
                    <div className="flex items-center gap-5">
                      <div className="h-14 w-14 flex items-center justify-center rounded-full text-white bg-blue-500">
                        <IoCallOutline className="text-3xl" />
                      </div>
                      <div>
                        <span className="text-xl font-semibold text-blue-500">
                          24/7 Service
                        </span>
                        <h3 className="text-2xl font-bold text-gray-900">
                          Call Us
                        </h3>
                      </div>
                    </div>
                    <p className="text-base text-center font-semibold text-gray-500 mt-5">
                      02269718456
                    </p>
                  </div>

                  <div className="p-5 rounded-xl border border-gray-200 bg-white shadow-lg">
                    <div className="flex items-center gap-5">
                      <div className="h-14 w-14 flex items-center justify-center rounded-full text-white bg-blue-500">
                        <IoLocationOutline className="text-3xl" />
                      </div>
                      <div>
                        <span className="text-xl font-semibold text-blue-500">
                          Location
                        </span>
                        <h3 className="text-2xl font-bold text-gray-900">
                          Visit Us
                        </h3>
                      </div>
                    </div>
                    <p className="text-base text-center font-semibold text-gray-500 mt-5">
                      8, The Green, STE A, Dover, Delaware
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid lg:grid-cols-2 grid-cols-1 gap-10 items-center mt-10">
                <img src={ContactImg} alt="" />

                <form className="mt-4" method="post" onSubmit={handleSubmit}>
                  <div className=" md:grid lg:grid lg:grid-cols-2 gap-3">
                    <div className="mb-4">
                      <label
                        htmlFor="name"
                        className="block text-md font-medium text-gray-300"
                      >
                        Your Name
                      </label>
                      <input
                        type="text"
                        name="user_name"
                        value={formData.user_name}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full border rounded-md border-slate-300"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-md font-medium text-gray-300"
                      >
                        Your Email
                      </label>
                      <input
                        type="email"
                        name="user_email"
                        value={formData.user_email}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full border rounded-md border-slate-300"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                    <div className="col-span-2 mb-2">
                      <label
                        htmlFor="message"
                        className="block text-md font-medium text-gray-300"
                      >
                        Message
                      </label>
                      <textarea
                        name="user_message"
                        value={formData.user_message}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full border rounded-md border-slate-300"
                        placeholder="Leave a message here"
                        style={{ height: "120px" }}
                        required
                      ></textarea>
                    </div>
                    <div className=" py-2 col-span-2 mb-4">
                      <div className=" flex items-center">
                        <LoadCanvasTemplateNoReload />
                        <IconButton
                          aria-label="Reload Captcha"
                          size="small"
                          color="primary"
                          onClick={() =>
                            loadCaptchaEnginge(6, "#000000", "#FFFFFF")
                          }
                        >
                          <RefreshIcon />
                        </IconButton>
                      </div>
                      <input
                        type="text"
                        name="captcha"
                        value={captchaValue}
                        onChange={(e) => setCaptchaValue(e.target.value)}
                        className="p-1 mt-2 w-[150px] border rounded-md border-slate-300"
                        placeholder="Enter Captcha"
                        required
                      />
                      {captchaError && (
                        <p className="text-red-500 text-sm mt-1">
                          {captchaError}
                        </p>
                      )}
                    </div>

                    <div className="col-span-2">
                      <button
                        className="btn bg-[#252f80] text-white hover:bg-[#2d3da5] rounded-pill py-3 px-5"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {showSpinner ? (
                          <div className="h-5 w-5 animate-spin rounded-full border-t-4 border-blue-600 mx-auto" />
                        ) : (
                          "Send Message"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Contact;
