import React from 'react'
import ImportanceChaos from './ImportanceChaos'

export default function index() {
  return (
    <div>
        <ImportanceChaos/>
    </div>
  )
}
