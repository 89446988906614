import React, { useState } from "react";
import question_svg from "../../Assests/img/question-circle-svgrepo-com.svg";
import user_svg from "../../Assests/img/user-circle-svgrepo-com.svg";
import Started_svg from "../../Assests/img/rocket_svg/rocket-svgrepo-com.svg";
import Key_svg from "../../Assests/img/key-svgrepo-com.svg";

export default function Faqs() {
  const [activeContent, setActiveContent] = useState("accountBtn");

  const faqData = [
    {
      question: "How do I create an account on SmartChaosLab?",
      answer:
        'Simply visit our homepage, click on the "Sign Up" button, and fill out the registration form. Once registered, you will receive an email verification link to activate your account.',
    },
    {
      question: "Can I manage multiple projects or teams under one account?",
      answer:
        "Yes, SmartChaosLab allows users to manage multiple projects or teams within a single account, providing flexibility and ease for diverse organizational needs.",
    },
    {
      question: "What should I do if I forget my password?",
      answer:
        'Click on the "Forgot Password" option on the login page, and follow the steps to reset it. You will receive an email with a link to create a new password.',
    },
    {
      question: "How does SmartChaosLab ensure my accounts security?",
      answer:
        "We employ state-of-the-art encryption, two-factor authentication, and rigorous security protocols to ensure that all user data and account details remain secure and confidential.",
    },
    {
      question: "How can I update my profile or account details?",
      answer:
        'Once logged in, go to the "Profile" or "Account Settings" section, where you can update personal details, change your password, or modify other preferences.',
    },
    {
      question: "Can I delete my account if needed?",
      answer:
        "Yes, you can request account deletion by reaching out to our support team. Please note that deleting your account will permanently remove all associated data and cannot be undone.",
    },
    {
      question: "How often does SmartChaosLab update its features?",
      answer:
        "We continuously work on improving our platform. Regular updates are rolled out every quarter, with minor patches or improvements released as needed.",
    },
    {
      question:
        "Can I integrate my SmartChaosLab account with other tools I use?",
      answer:
        'Absolutely! SmartChaosLab offers integration options for various popular tools and platforms. Visit the "Integrations" section in your dashboard for more details.',
    },
    {
      question:
        "Are there any user guidelines or community rules I should be aware of?",
      answer:
        "Yes, we encourage all users to read our community guidelines and terms of service available on our website to ensure a positive and respectful environment for everyone.",
    },
    {
      question:
        "Who can I reach out to for any account-related queries or issues?",
      answer:
        'Our dedicated support team is available to assist you. You can contact them through the "Help" or "Support" section in your dashboard, or email us directly at support@smartchaoslab.com.',
    },
  ];

  const faqData1 = [
    {
      question:
        "How do I get started with SmartChaosLab after creating an account?",
      answer:
        'Once your account is activated, log in and navigate to the "Dashboard." There, you will find a guided tutorial and resources to help you set up your first chaos experiment.',
    },
    {
      question:
        "What kind of support can I expect as I begin using SmartChaosLab?",
      answer:
        "Our dedicated support team is available around the clock. Additionally, we offer a range of resources such as detailed documentation, video tutorials, and a community forum where experienced users share tips and insights.",
    },
    {
      question:
        "Are there sample experiments or templates to help me get started?",
      answer:
        "Absolutely! SmartChaosLab provides a library of pre-defined experiment templates. These templates cover a range of common scenarios and can be customized as per your requirements.",
    },
    {
      question: "Are there any resources or tutorials available for beginners?",
      answer:
        'Yes, SmartChaosLab offers comprehensive documentation, video tutorials, and sample experiments to assist newcomers. You can find these resources under the "Help" or "Tutorials" section.',
    },
    {
      question: "What are the system prerequisites for running SmartChaosLab?",
      answer:
        "SmartChaosLab is cloud-based and requires an active internet connection. For the best experience, we recommend a modern browser and a stable broadband connection.",
    },
    {
      question:
        "Can I migrate data or experiments from another platform to SmartChaosLab?",
      answer:
        "Yes, we offer tools and support for migrating data or experiments from other platforms. Reach out to our support team for detailed guidance.",
    },
    {
      question:
        "How long does it typically take to see results from the chaos experiments?",
      answer:
        "The duration can vary based on the complexity of the experiment and your systems architecture. However, with SmartChaosLabs realtime monitoring, you can start observing the effects immediately.",
    },
    {
      question:
        "What kind of data should I prepare or be aware of before starting an experiment?",
      answer:
        "Before initiating a chaos experiment, ensure you have baseline data about your systems normal operations. This data will help in comparing and analyzing the impact of your chaos experiments.",
    },
    {
      question:
        "I have a hybrid infrastructure with both cloud and on-premises systems. Can I use SmartChaosLab for such a setup?",
      answer:
        "Yes, SmartChaosLab is designed to accommodate both cloud and on-premises infrastructures. You can configure the platform to connect to your systems, regardless of where they are hosted.",
    },
    {
      question: "Can I integrate SmartChaosLab with my current CI/CD pipeline?",
      answer:
        "Yes, SmartChaosLab offers integrations with popular CI/CD tools, allowing you to incorporate chaos engineering into your development and deployment cycles seamlessly.",
    },
    {
      question:
        "What if I encounter an issue or bug while using SmartChaosLab?",
      answer:
        'We strive to provide a bug-free experience, but if you do encounter any issues, please report them immediately through the "Report Issue" feature or contact our support team for assistance.',
    },
    {
      question:
        "I have a specific chaos scenario not covered in the templates. Can I create custom experiments?",
      answer:
        "Absolutely! While we provide numerous predefined templates, SmartChaosLab is designed to support custom experiments. You can define your parameters, conditions, and triggers as per your unique requirements.",
    },
    {
      question:
        "How does SmartChaosLab handle system anomalies detected during experiments?",
      answer:
        "SmartChaosLabs AI-driven anomaly detection proactively identifies any unexpected behaviors or anomalies during experiments, allowing you to take immediate corrective actions or delve deeper into root cause analysis.",
    },
    {
      question:
        "Do I need any specialized training to use SmartChaosLab effectively?",
      answer:
        "While the platform is user-friendly and intuitive, we offer a range of training materials, webinars, and tutorials to help users maximize the benefits of chaos engineering using SmartChaosLab.",
    },
    {
      question:
        "Can I integrate my monitoring and observability tools with SmartChaosLab?",
      answer:
        "Yes, SmartChaosLab offers integration options with popular monitoring and observability platforms. This ensures you can view and correlate chaos experiment outcomes with your existing monitoring data seamlessly.",
    },
    {
      question:
        "How can I provide feedback or suggest new features for SmartChaosLab?",
      answer:
        'We value user feedback immensely. Within the dashboard, youll find a "Feedback & Suggestions" section where you can share your thoughts, experiences, and feature requests.',
    },
  ];

  const faqData2 = [
    {
      question:
        "What are the differences between the various licensing options?",
      answer:
        "We offer different licensing tiers, each with its set of features. The basic difference is in the number of features, level of support, and scalability options. Detailed comparisons can be found on our Pricing page.",
    },
    {
      question: "Can I switch between licenses midway?",
      answer:
        "Yes, you can upgrade or downgrade your license depending on your needs. When upgrading, the difference in pricing will be prorated.",
    },
    {
      question: "Is there a refund policy if I choose to cancel my license?",
      answer:
        "We offer a 30-day money-back guarantee. If youre not satisfied within the first 30 days of your subscription, you can request a full refund.",
    },
    {
      question: "How are the licenses billed? Monthly or annually?",
      answer:
        "We offer both monthly and annual billing options. Annual licenses come with a discount compared to monthly billing.",
    },
    {
      question:
        "Are there any discounts for non-profits or educational institutions?",
      answer:
        "Yes, we offer special pricing for non-profits, educational institutions, and startups. Please contact our sales team for more details.",
    },
  ];

  const handleButtonClick = (index) => {
    setActiveContent(index);
  };

  return (
    <section className="flex items-center bg-black font-poppins">
      <div className="max-w-5xl p-4 mx-auto">
        <div className="text-center mb-14">
          <h1 className="mb-4 text-3xl pt-5 md:text-4xl md:pt-5 lg:pt-5 lg:text-5xl xl:pt-5 xl:text-5xl font-bold text-white pb-2">
            {" "}
            Frequently Asked Questions{" "}
          </h1>
          <hr className="ml-10 mr-10 md:ml-60 md:mr-60 xl:ml-[250px] xl:mr-[250px] mt-4 border-2 border-[#452283]" />
        </div>
        <div className="max-w-4xl mx-auto mb-12">
          <div className="flex flex-wrap -mx-4 justify-center">
            <div className="w-full px-4 mb-6 md:w-1/3 lg:w-1/5 lg:mb-0">
              <button
                id="accountBtn"
                className={`w-full rounded-xl md:w-full md:rounded-xl lg:rounded-xl lg:w-full xl:w-full py-6 xl:rounded-xl ${
                  activeContent === "accountBtn"
                    ? "bg-[#49238b]"
                    : "bg-gray-100 hover:bg-violet-400"
                }`}
                onClick={() => handleButtonClick("accountBtn")}
              >
                <img
                  className="w-10 h-10 md:w-10 md:h-10 lg:w-10 lg:h-10 xl:w-10 xl:h-10 mx-auto mb-3 text-gray-600"
                  src={user_svg}
                />
                <p className="text-lg font-semibold text-black">Account</p>
              </button>
            </div>
            <div className="w-full px-3 mb-6 md:w-1/3 lg:mb-0 lg:w-1/5">
              <button
                id="gettingStartedBtn"
                className={`w-full rounded-xl md:w-full md:rounded-xl lg:rounded-xl lg:w-full xl:w-full py-6 xl:rounded-xl ${
                  activeContent === "gettingStartedBtn"
                    ? "bg-[#49238b]"
                    : "bg-gray-100 hover:bg-violet-400"
                }`}
                onClick={() => handleButtonClick("gettingStartedBtn")}
              >
                <img
                  className="w-10 h-10 md:w-10 md:h-10 lg:w-10 lg:h-10 xl:w-10 xl:h-10 mx-auto mb-3 text-gray-600"
                  src={Started_svg}
                />
                <p className="text-lg font-semibold text-black">
                  Getting Started
                </p>
              </button>
            </div>
            <div className="w-full px-4 mb-6 md:w-1/3 lg:w-1/5 lg:mb-0">
              <button
                id="licenseBtn"
                className={`w-full rounded-xl md:w-full md:rounded-xl lg:rounded-xl lg:w-full xl:w-full py-6 xl:rounded-xl ${
                  activeContent === "licenseBtn"
                    ? "bg-[#49238b]"
                    : "bg-gray-100 hover:bg-violet-400"
                }`}
                onClick={() => handleButtonClick("licenseBtn")}
              >
                <img
                  className="w-10 h-10 md:w-10 md:h-10 lg:w-10 lg:h-10 xl:w-10 xl:h-10 mx-auto mb-3 text-gray-600"
                  src={Key_svg}
                />
                <p className="text-lg font-semibold text-black">License</p>
              </button>
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
            {(activeContent === "gettingStartedBtn"
              ? faqData1
              : activeContent === "licenseBtn"
              ? faqData2
              : faqData
            ).map((faq, index) => (
              <div key={index} className="flex">
                <img
                  className="w-8 h-8 md:w-8 md:h-8 lg:w-8 lg:h-8 xl:w-8 xl:h-8 mr-2"
                  src={question_svg}
                />
                <div className="flex-1">
                  <span
                    className={`font-bold text-white ${
                      activeContent === index ? "text-blue-400" : ""
                    }`}
                    onClick={() => handleButtonClick(index)}
                  >
                    {faq.question}
                  </span>
                  <p className="text-gray-300 pt-2">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
