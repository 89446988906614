import React from "react";
import Game_svg from "../../Assests/img/game-9-svgrepo-com.svg";
import Target_svg from "../../Assests/img/target-svgrepo-com.svg";
import RTMR_svg from "../../Assests/img/heart-beat-frequency-svgrepo-com.svg";
import Chaos_as_code_svg from "../../Assests/img/code-box-svgrepo-com.svg";
import Scalability_svg from "../../Assests/img/handshake-svgrepo-com.svg";
import Experimentation_svg from "../../Assests/img/test-tube-lab-svgrepo-com.svg";
import Future_svg from "../../Assests/img/firewall-svgrepo-com.svg";
import Crafted_svg from "../../Assests/img/man-svgrepo-com.svg";

const iconRender = (val) => {
  switch (val) {
    case "Game":
      return (
        <img
          className="w-10 md:w-10 lg:w-10 xl:w-10"
          src={Game_svg}
          alt="Game"
        />
      );
    case "Target":
      return (
        <img
          className="w-10 md:w-10 lg:w-10 xl:w-10"
          src={Target_svg}
          alt="Target"
        />
      );
    case "RTMR":
      return (
        <img
          className="w-10 md:w-10 lg:w-10 xl:w-10"
          src={RTMR_svg}
          alt="RTMR"
        />
      );
    case "Chaos":
      return (
        <img
          className="w-10 md:w-10 lg:w-10 xl:w-10"
          src={Chaos_as_code_svg}
          alt="Chaos"
        />
      );
    case "Scale":
      return (
        <img
          className="w-10 md:w-10 lg:w-10 xl:w-10"
          src={Scalability_svg}
          alt="Scale"
        />
      );
    case "Exp":
      return (
        <img
          className="w-10 md:w-10 lg:w-10 xl:w-10"
          src={Experimentation_svg}
          alt="Exp"
        />
      );
    case "Future":
      return (
        <img
          className="w-10 md:w-10 lg:w-10 xl:w-10"
          src={Future_svg}
          alt="Future"
        />
      );
    case "Crafted":
      return (
        <img
          className="w-10 md:w-10 lg:w-10 xl:w-10"
          src={Crafted_svg}
          alt="Crafted"
        />
      );
    default:
      return <>No Icon</>;
  }
};

const ServiceCard = ({ title, description, icon }) => {
  return (
    <div className="p-5 sm:p-6 lg:p-8 rounded-3xl border border-gray-500 bg-gradient-to-br from-white/20 to-white/0 relative overflow-hidden">
      <div className="flex flex-row items-center">
        <div className="rounded-xl mr-5 bg-gray-200  p-3 text-gray-900 flex-shrink-0">
          {iconRender(icon)}
        </div>
        <h2 className="xl:text-lg md:text-xl font-semibold text-white">
          {title}
        </h2>
      </div>
      <div className="mt-6 space-y-4 relative">
        <p className="text-white">{description}</p>
      </div>
      {/* <span className="absolute w-32 aspect-square -bottom-16 -right-16 bg-sky-600/10 rounded-full" /> */}
    </div>
  );
};

const services = [
  {
    id: 1,
    title: "Game Theory Integration",
    description:
      "SmartChaosLab leverages game theory principles, including the Nash Equilibrium, to provide unparalleled insights into system behaviors. This strategic approach ensures that chaos experiments are conducted with a focus on optimal component interactions, enhancing system resilience.",
    icon: "Game",
  },
  {
    id: 2,
    title: "Precision and Targeting",
    description:
      "Unlike traditional chaos tools, SmartChaosLab offers AI/ML-powered recommendations that are tailored to your system's unique characteristics. This precision minimizes the risk of unintended disruptions, making chaos experiments highly targeted and effective.",
    icon: "Target",
  },
  {
    id: 3,
    title: "Real-time Monitoring",
    description:
      "The platform provides real-time monitoring and comprehensive reporting, allowing you to observe the effects of chaos experiments as they happen. This capability enables quick responses to issues, reducing potential damage.",
    icon: "RTMR",
  },
  {
    id: 4,
    title: "Chaos as Code",
    description:
      "SmartChaosLab allows you to manage chaos experiments as code, promoting consistency, repeatability, and control. This feature ensures that chaos engineering is conducted with efficiency and predictability.",
    icon: "Chaos",
  },
  {
    id: 5,
    title: "Collaboration and Scalability",
    description:
      "The platform is designed to scale seamlessly, enabling controlled chaos experiments across diverse environments. Collaboration tools facilitate effective teamwork, making it suitable for organizations of all sizes.",
    icon: "Scale",
  },
  {
    id: 6,
    title: "Effortless Experimentation",
    description:
      "SmartChaosLab simplifies the chaos experiment lifecycle with AI-assisted script generation and real-time monitoring. This streamlines the chaos engineering process, even for those new to the practice.",
    icon: "Exp",
  },
  {
    id: 7,
    title: "Future-Proof Systems",
    description:
      "SmartChaosLab is built to adapt seamlessly to future innovations and challenges, ensuring that your systems remain prepared for whatever the future holds.",
    icon: "Future",
  },
  {
    id: 8,
    title: "Crafted by Aero Aegis",
    description:
      "SmartChaosLab is crafted by Aero Aegis, a team dedicated to pushing the boundaries of innovation and ensuring that the platform incorporates cutting-edge technology and strategic resilience.",
    icon: "Crafted",
  },
];

export default function Special() {
  return (
    <section className="lg:py-10 md:py-10 bg-black">
      <div className="max-w-7xl mx-auto px-5 sm:px-10 md:px-12 lg:px-5 flex flex-col items-start gap-10 xl:gap-14">
        <div className="text-center max-w-3xl mx-auto space-y-4">
          <h1 className="text-white font-semibold xl:pb-2 text-4xl md:text-5xl lg:text-5xl xl:text-5xl">
            Why Is It Special
          </h1>
          <hr className="ml-10 mr-10 md:ml-[100px] md:mr-[100px] xl:ml-[100px] xl:mr-[100px] mt-4 border-2 border-[#452283]" />
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8">
          {services.map((service) => (
            <ServiceCard key={service.id} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
}
