import React from "react";
import check_svg from "../../Assests/img/check-circle-svgrepo-com.svg";
import Game_Theory_img from "../../Assests/img/KeyOfferings/numerology-concept-still-life.jpg";
import AI_Power_img from "../../Assests/img/KeyOfferings/person-utilizing-ai-tools-daily-tasks.jpg";
import AI_Scripts_img from "../../Assests/img/KeyOfferings/people-generating-images-using-artificial-intelligence-laptop.jpg";
import Laboratory_img from "../../Assests/img/KeyOfferings/laboratory-glassware-composition.jpg";
import Chaos_exp_img from "../../Assests/img/KeyOfferings/close-up-hands-with-thumbs-up.jpg";
import AI_Driven_img from "../../Assests/img/KeyOfferings/rm373batch5-18.jpg";
import Cloud_img from "../../Assests/img/KeyOfferings/digital-cloud-data-storage-digital-concept-cloudscape-digital-online-service-global-network-database-backup-computer-infrastructure-technology-solution.jpg";
import Chaos_code_img from "../../Assests/img/KeyOfferings/turned-gray-laptop-computer.jpg";
import Real_Time_img from "../../Assests/img/KeyOfferings/planning-work.jpg";

const FeatureItem = ({ id, title, advantages, image }) => {
  return (
    <div
      className={`flex flex-col md:items-center gap-10 lg:gap-14 ${
        id % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
      }`}
    >
      <div className="md:w-[48%] lg:w-[48%] xl:w-[48%] md:py-6 xl:py-12 space-y-8">
        <div className="space-y-6">
          <h2 className="mt-4 text-xl md:text-2xl lg:text-2xl xl:text-4xl font-semibold text-white lg:text-center">
            {title}
          </h2>
        </div>
        <div className="text-sm md:text-sm lg:text-lg xl:text-xl space-y-5 children:flex children:items-start children:gap-4 children:text-gray-600 text-gray-200">
          <ul role="list">
            {advantages.map((advantage) => (
              <li key={advantage.id} className="flex pb-5">
                <img className="w-8 md:w-8 lg:w-8 mr-4" src={check_svg} />
                {advantage.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex-1 relative lg:mt-10 p-1 overflow-hidden">
        <img className="lg:w-100 lg:h-100" src={image} alt="illustration" />
      </div>
    </div>
  );
};

const features = [
  {
    id: 1,
    title: "Game Theory-Driven Insights",
    icon: "cross",
    advantages: [
      {
        id: 1,
        text: "At the heart of SmartChaosLab's capabilities lies the power of game theory-driven insights. This feature revolutionizes your approach to chaos engineering by incorporating strategic decision-making principles into the process.",
      },
      {
        id: 2,
        text: "Game theory, including concepts like the Nash Equilibrium, is employed to provide you with unparalleled insights into your system's behavior.",
      },
      {
        id: 3,
        text: "By applying game theory-driven analysis, you can identify optimal component interplays that fortify your system's resilience.",
      },
    ],
    image: Game_Theory_img,
  },
  {
    id: 2,
    title: "AI/ML-Powered Recommendations",
    icon: "cross",
    advantages: [
      {
        id: 1,
        text: "SmartChaosLab's AI/ML-powered recommendations represent a revolutionary leap forward in the world of chaos engineering. Our platform leverages the formidable capabilities of Artificial Intelligence (AI) and Machine Learning (ML) to provide you with highly targeted and datadriven recommendations that enhance your system's reliability and performance.",
      },
    ],
    image: AI_Power_img,
  },
  {
    id: 3,
    title: "Generative AI Script Generation",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "SmartChaosLab offers a groundbreaking feature that leverages the power of Generative Artificial Intelligence (AI) for script generation. This advanced capability transforms the way chaos experiments are created and executed, enhancing the efficiency and accuracy of the entire process.",
      },
    ],
    image: AI_Scripts_img,
  },
  {
    id: 4,
    title: "Orchestrated Chaos Experiments",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "Chaos engineering is all about controlled experimentation, and at the heart of SmartChaosLab lies the ability to orchestrate chaos experiments with precision and finesse.",
      },
    ],
    image: Laboratory_img,
  },
  {
    id: 5,
    title: "Real-time Monitoring & Reporting",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "SmartChaosLab offers real-time monitoring and reporting capabilities that provide you with invaluable insights into your system's behavior as chaos experiments unfold. With real-time monitoring, you can observe how your applications and infrastructure respond to various fault injections and chaos scenarios as they happen. This level of transparency allows you to make informed decisions, react promptly to issues, and assess the effectiveness of your chaos engineering experiments in real-time.",
      },
    ],
    image: Real_Time_img,
  },
  {
    id: 6,
    title: "Chaos as Code",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "Chaos as Code is a powerful feature that enables you to manage and version-control your chaos experiments seamlessly within SmartChaosLab. By integrating chaos engineering into your existing codebase and workflows, you can orchestrate, analyze, and refine experiments directly from the platform. This feature promotes collaboration among development and operations teams, streamlines the chaos engineering process, and ensures that chaos experiments are an integral part of your continuous integration and continuous delivery (CI/CD) pipeline. With Chaos as Code, you can easily automate and scale your chaos engineering efforts while maintaining control and visibility over your experiments.",
      },
    ],
    image: Chaos_code_img,
  },
  {
    id: 7,
    title: "Cloud Agnostic Fault Injection",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "Seamlessly integrate with major cloud platforms like AWS, Azure, and GCP. Inject controlled faults into your infrastructure, simulating realworld failure scenarios while ensuring a secure testing environment.",
      },
    ],
    image: Cloud_img,
  },
  {
    id: 8,
    title: "AI-Driven Anomaly Detection",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "Detect anomalies and aberrations in key metrics with advanced AI and ML algorithms. Gain insights into potential vulnerabilities that traditional monitoring might miss.",
      },
    ],
    image: AI_Driven_img,
  },
  {
    id: 9,
    title: "Chaos Experiment Recommendations",
    icon: "sync",
    advantages: [
      {
        id: 1,
        text: "Beyond anomaly detection, receive personalized recommendations for chaos experiments. Tailored to your application's characteristics, these recommendations guide you in addressing specific weak points and enhancing reliability.",
      },
    ],
    image: Chaos_exp_img,
  },
];

export default function CoreFeature() {
  return (
    <section className="py-10 bg-black">
      <div className="max-w-7xl mx-auto px-5 sm:px-10 md:px-12 lg:px-5">
        <div className="flex flex-col">
          <div className="flex flex-col justify-center text-center  mx-auto md:max-w-2xl space-y-5">
            {/* <span className="rounded-lg bg-blue-100 p-2 md:p-2 lg:p-2 xl:p-2 w-max mx-auto font-semibold tracking-wide text-purple-800">Key Offerings</span> */}
            <h1 className="pt-5 text-3xl font-semibold inline-block text-white md:text-4xl xl:text-5xl leading-tight uppercase ">
              Core Features
            </h1>
            <hr className="ml-10 mr-10 md:ml-60 md:mr-60 xl:ml-[50px] xl:mr-[50px] mt-4 border-2 border-[#452283]" />
          </div>
          {/* <hr className='ml-10 mr-10 md:ml-60 md:mr-60 lg:ml-96 lg:mr-96 border-2 border-[#452283]'/> */}
          <div className="grid divide-y divide-gray-300 gap-8 children:py-5 first:pt-0 last:pb-0">
            {features.map((feature) => (
              <FeatureItem key={feature.id} {...feature} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
