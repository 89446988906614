import React from "react";
import behind_img from "../../Assests/img/pexels-cottonbro-studio-2925322.jpg";

export default function Scenes() {
  return (
    <div className="relative group">
      <img
        className="object-cover w-full h-full sm:h-96"
        src={behind_img}
        alt=""
      />
      <div className="absolute top-1/2 left-1/2 bg-black bg-opacity-55 w-full h-full  text-center transform -translate-x-1/2 -translate-y-1/2 text-white  font-bold transition duration-300 opacity-100 group-hover:opacity-0  hover:bg-opacity-100">
        <p className=" text-center text-lg md:text-4xl lg:text-6xl  my-20 md:my-36 lg:my-36">
          Behind the Scenes
        </p>
      </div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 transition duration-700 -translate-y-1/2  opacity-0 w-full h-full group-hover:opacity-100 bg-black bg-opacity-80  ">
        <p className="absolute text-center text-[7px] md:text-sm lg:text-xl text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
          Crafted by Aero Aegis, a team dedicated to pushing the boundaries of
          innovation, SmartChaosLab is designed to empower organizations to take
          control of their system reliability and performance. By seamlessly
          integrating game theory principles, AI-driven insights, and generative
          AI scripting, SmartChaosLab offers a comprehensive suite of tools to
          identify vulnerabilities, strengthen system resilience, and enhance
          overall performance.
          <br />
          <br />
          <span className="">
            Welcome to the future of chaos engineering with SmartChaosLab,
            crafted by Aero Aegis. Elevate your system's reliability and
            resilience to unprecedented heights and embark on a journey of
            digital transformation and operational excellence.
          </span>
        </p>
      </div>
    </div>
  );
}
