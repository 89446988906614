import React from 'react'
import Faqs from './Faqs'

export default function index() {
  return (
    <div>
        <Faqs/>
    </div>
  )
}
