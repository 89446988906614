import React from 'react'
import Timeline from './Timeline'

export default function index() {
  return (
    <div>
        <Timeline/>
    </div>
  )
}
