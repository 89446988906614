import React, { useEffect, useRef, useState } from "react";
import "./Homepage/index.css";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Modal from "../Pages/Modal/Modal";
import smartchaoslab_pdf from "../Assests/PDF/smartchaoslab_product_guide.pdf";
import nav_bar_img from "../Assests/img/Cloud_img.png";

const FlyoutLink = ({ children, href, FlyoutContent }) => {
  const [open, setOpen] = useState(false);
  const showFlyout = FlyoutContent && open;

  return (
    <div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className="relative w-fit h-fit"
    >
      <a href={href} className="relative text-white">
        {children}
        <span
          style={{
            transform: showFlyout ? "scaleX(1)" : "scaleX(0)",
          }}
          className="absolute -bottom-2 -left-2 -right-2 h-1 origin-left scale-x-0 rounded-full bg-blue-800 transition-transform duration-300 ease-out"
        />
      </a>
      <AnimatePresence>
        {showFlyout && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            style={{ translateX: "-50%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="absolute left-1/2 top-12 bg-black text-black"
          >
            <div className="absolute left-0 right-0 bg-transparent" />
            <div className="absolute left-1/2 rotate-45 bg-black" />
            <FlyoutContent />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const PricingContent = () => {
  return (
    <div className="lg:w-36 lg:mt-[-10px] bg-black text-white pt-0 shadow-xl">
      <div className="mb-3 space-y-3">
        <a
          href={smartchaoslab_pdf}
          download="SmartChaosLab"
          target="_blank"
          rel="noreferrer"
          className="block font-semibold text-center hover:text-gray-400"
        >
          PROJECT GUIDE
        </a>
      </div>
    </div>
  );
};

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);

  const navRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navRef]);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Sticky Header
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const isScrolled = scrollPosition > 50;

      setIsSticky(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerClass = isSticky
    ? "fixed top-0 left-0 w-full bg-white shadow-md z-50  transition-all duration-300 ease-in-out transform translate-y-0 "
    : "";

  return (
    <div className={headerClass}>
      <div class="bg-black">
        <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div class="relative flex items-center justify-between">
            <a href="/" class="inline-flex items-center">
              <img
                className="w-16 md:w-16 lg:w-20 xl:w-24 object-contain"
                src={nav_bar_img}
              />
              <div className="relative flex flex-col items-center justify-between">
                <span class="md:text-2xl lg:text-3xl text-2xl font-bold tracking-wide text-white uppercase">
                  SMARTCHAOSLAB
                </span>
                <span class="md:text-[9px] lg:text-[11px] text-[8px] font-semibold tracking-wide text-gray-300 uppercase">
                  Intelligent Resilience for the Cloud Era
                </span>
              </div>
            </a>
            <ul class="flex items-center hidden space-x-8 lg:flex">
              <Link to={"/key-offerings"}>
                <li>
                  <a
                    href="/"
                    aria-label="KEY OFFERINGS"
                    title="KEY OFFERINGS"
                    className="font-medium text-sm tracking-wide text-gray-100 hover:text-blue-500 transition-colors duration-200 hover:text-teal-accent-400"
                  >
                    KEY OFFERINGS
                  </a>
                </li>
              </Link>
              <Link to={"/pricing"}>
                <li>
                  <a
                    href="/"
                    aria-label="PRICING"
                    title="PRICING"
                    className="font-medium text-sm tracking-wide text-gray-100 hover:text-blue-500 transition-colors duration-200 hover:text-teal-accent-400"
                  >
                    PRICING
                  </a>
                </li>
              </Link>
              <Link to={"/faq"}>
                <li>
                  <a
                    href="/"
                    aria-label="FAQ"
                    title="FAQ"
                    className="font-medium text-sm tracking-wide text-gray-100 hover:text-blue-500 transition-colors duration-200 hover:text-teal-accent-400"
                  >
                    FAQ
                  </a>
                </li>
              </Link>
              <li>
                <a
                  aria-label="DOCUMENTATION"
                  title="DOCUMENTATION"
                  className="font-medium text-sm tracking-wide text-gray-100 hover:text-blue-500 transition-colors duration-200 hover:text-teal-accent-400"
                >
                  <FlyoutLink FlyoutContent={PricingContent}>
                    DOCUMENTATION
                  </FlyoutLink>
                </a>
              </li>
              <Link to={"/contact"}>
                <li>
                  <a
                    href="/"
                    aria-label="CONTACT"
                    title="CONTACT"
                    className="font-medium text-sm tracking-wide text-gray-100 hover:text-blue-500 transition-colors duration-200 hover:text-teal-accent-400"
                  >
                    CONTACT
                  </a>
                </li>
              </Link>
            </ul>
            <ul class="flex items-center hidden space-x-8 lg:flex">
              <li>
                <button
                  href="/"
                  className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded-3xl shadow-md bg-blue-600 hover:bg-blue-500 focus:shadow-outline focus:outline-none"
                  aria-label="Get In Touch"
                  title="Get In Touch"
                  onClick={openModal}
                >
                  Get In Touch
                </button>
              </li>
            </ul>
            {/* Modal */}
            <Modal isOpen={isModalOpen} closeModal={closeModal} />
            <div className="lg:hidden" ref={navRef}>
              <button
                aria-label="Open Menu"
                title="Open Menu"
                className="p-2 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
              {isMenuOpen && (
                <div className="absolute top-0 left-0 w-full z-10">
                  <div className="p-5 bg-black  shadow-sm">
                    <div className="flex items-center justify-between mb-4">
                      <div>
                        <a
                          href="/"
                          aria-label="SMARTCHAOSLAB"
                          title="SMARTCHAOSLAB"
                          className="inline-flex items-center mr-8"
                        >
                          <img
                            alt="logo_img"
                            src={nav_bar_img}
                            className="w-12 md:w-14 lg:w-14"
                          ></img>
                          <span className="ml-2 text-[21px] font-bold text-white uppercase">
                            SMARTCHAOSLAB
                          </span>
                        </a>
                      </div>
                      <div>
                        <button
                          aria-label="Close Menu"
                          title="Close Menu"
                          className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <svg
                            className="w-5 text-gray-600"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <nav>
                      <ul className="space-y-4">
                        <Link to={"/key-offerings"}>
                          <li>
                            <a
                              href="/"
                              aria-label="KEY OFFERINGS"
                              title="KEY OFFERINGS"
                              className="font-medium tracking-wide text-gray-100 hover:text-blue-500 transition-colors duration-200 hover:text-teal-accent-400"
                            >
                              KEY OFFERINGS
                            </a>
                          </li>
                        </Link>
                        <Link to={"/pricing"}>
                          <li>
                            <a
                              href="/"
                              aria-label="PRICING"
                              title="PRICING"
                              className="font-medium tracking-wide text-gray-100 hover:text-blue-500 transition-colors duration-200 hover:text-teal-accent-400"
                            >
                              PRICING
                            </a>
                          </li>
                        </Link>
                        <Link to={"/faq"}>
                          <li>
                            <a
                              href="/"
                              aria-label="FAQ"
                              title="FAQ"
                              className="font-medium tracking-wide text-gray-100 hover:text-blue-500 transition-colors duration-200 hover:text-teal-accent-400"
                            >
                              FAQ
                            </a>
                          </li>
                        </Link>
                        <Link>
                          <li>
                            <a
                              aria-label="DOCUMENTATION"
                              title="DOCUMENTATION"
                              className="font-medium tracking-wide text-gray-100 hover:text-blue-500 transition-colors duration-200 hover:text-teal-accent-400"
                            >
                              DOCUMENTATION
                            </a>
                          </li>
                        </Link>
                        <Link to={"/contact"}>
                          <li>
                            <a
                              href="/"
                              aria-label="CONTACT"
                              title="CONTACT"
                              className="font-medium tracking-wide text-gray-100 hover:text-blue-500 transition-colors duration-200 hover:text-teal-accent-400"
                            >
                              CONTACT
                            </a>
                          </li>
                        </Link>
                        <li>
                          <button
                            href="/"
                            className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-600 hover:bg-blue-500 focus:shadow-outline focus:outline-none"
                            aria-label="Get In Touch"
                            title="Get In Touch"
                            onClick={openModal}
                          >
                            Get In Touch
                          </button>
                        </li>
                      </ul>
                      {/* Modal */}
                      <Modal isOpen={isModalOpen} closeModal={closeModal} />
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
