import React, { useState, useEffect } from "react";
import Shield_svg from "../../Assests/img/shield-minimalistic-svgrepo-com.svg";
import Flask_svg from "../../Assests/img/test-tube-lab-svgrepo-com.svg";
import Settings_svg from "../../Assests/img/settings-svgrepo-com.svg";
import Enhanced_svg from "../../Assests/img/download-svgrepo-com.svg";

const cardData = [
  {
    title: "Enhanced System Resilience",
    description:
      "By proactively identifying and addressing potential vulnerabilities through targeted chaos experiments, SmartChaosLab ensures that your systems are prepared to withstand disruptions. This heightened resilience minimizes the risk of unexpected system failures, reduces downtime, and enhances your organization's ability to deliver consistent, reliable services to your customers.",
    image: (
      <img
        src={Enhanced_svg}
        alt="Author Avatar"
        className="xl:w-20 xl:h-20 object-cover rounded-2xl"
      />
    ),
  },
  {
    title: "Strategic System Management",
    description:
      "Leverages game theory principles and AI-driven insights, teams can anticipate potential issues and make strategic decisions to mitigate them. Optimized Resource Allocation ensures infrastructure is deployed where it's needed most, reducing waste, and optimizing costs. Further, Enhanced Risk Mitigation and Data-Driven Insights identifies vulnerabilities and potential points of failure in advance, thus improving system resilience and minimizing downtime.",
    image: (
      <img
        src={Enhanced_svg}
        alt="Author Avatar"
        className="xl:w-20 xl:h-20 object-cover rounded-2xl"
      />
    ),
  },
  {
    title: "Effortless Experimentation",
    description:
      "SmartChaosLab simplifies the entire chaos experiment lifecycle. Its AI-assisted script generation and real-time monitoring capabilities streamline the process, allowing organizations to implement chaos engineering with ease and confidence.",
    image: (
      <img
        src={Enhanced_svg}
        alt="Author Avatar"
        className="xl:w-20 xl:h-20 object-cover rounded-2xl"
      />
    ),
  },
  {
    title: "Future-Proof Systems",
    description:
      "SmartChaosLab is designed with adaptability in mind. Organizations can seamlessly integrate it with their existing infrastructure and tools,ensuring that they stay ahead of the curve and remain prepared for future innovations and challenges.",
    image: (
      <img
        src={Enhanced_svg}
        alt="Author Avatar"
        className="xl:w-20 xl:h-20 object-cover rounded-2xl"
      />
    ),
  },
];

export default function BenefitsOfChaos() {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const handleNext = () => {
    if (currentCardIndex < cardData.length - 1) {
      setCurrentCardIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentCardIndex(0);
    }
  };

  const handlePrev = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex((prevIndex) => prevIndex - 1);
    } else {
      setCurrentCardIndex(cardData.length - 1);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentCardIndex]);

  const currentCard = cardData[currentCardIndex];

  return (
    <div className="bg-black">
      <div className="text-center">
        <h1 className="text-white font-semibold text-xl md:text-5xl lg:text-5xl xl:text-5xl pt-10">
          Benefits Of SmartChaosLab
        </h1>
        <hr className="ml-10 mr-10 md:ml-60 md:mr-60 xl:ml-[600px] xl:mr-[600px] mt-4 border-2 border-[#452283]" />
      </div>
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-5 sm:px-10 md:px-12 lg:px-5 space-y-16">
          <div className="grid md:grid-cols-3 lg:grid-cols-2 md:gap-6 relative">
            <div
              className="absolute -left-10 md:left-0 inset-y-0 w-3/5 z-0"
              style={{ height: "520px" }}
            >
              <img
                src="https://img.freepik.com/free-photo/businessman-pointing-chart-digital-monitor-generated-by-ai_188544-22442.jpg?t=st=1705997640~exp=1706001240~hmac=e8dbdad91a0ba6452444e578a719e113c50f1a8714b7211371f1aef0a386cdec&w=1060"
                alt="woman write"
                width={1500}
                height={1500}
                className="w-full h-full object-cover rounded-2xl"
              />
            </div>
            <div className="flex col-span-1" />
            <div className="w-full col-span-1 overflow-hidden md:col-span-2 lg:col-span-1 relative flex flex-col gap-8 z-[5] py-10 md:py-14">
              <div
                className="h-auto  rounded-2xl shadow-lg shadow-gray-200/40 bg-gray-300 border border-gray-200/50"
                style={{ height: "365px" }}
              >
                <div className="h-full">
                  <div className="flex gap-5 md:gap-6">
                    {/* <div className="flex min-w-max">{currentCard.image}</div> */}
                    <div className="flex flex-col gap-5">
                      <div className="p-4 sm:p-6 md:p-8 lg:p-10 flex flex-col gap-5">
                        <div className="flex flex-col">
                          <span className="text-xl xl:text-4xl md:text-2xl font-bold text-black">
                            {currentCard.title}
                          </span>
                        </div>
                        <div>
                          <p className="text-[12px] xl:text-[17px] line-clamp-7 text-black">
                            {currentCard.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex md:justify-end">
                <div className="flex items-center gap-4 w-max p-1.5 rounded-full bg-white shadow-lg shadow-gray-200/30 border border-gray-200/40">
                  <button
                    aria-label="prev"
                    onClick={handlePrev}
                    className="outline-none text-gray-700 transition hover:text-blue-600 p-2 md:p-2.5 hover:bg-blue-100 rounded-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <div className="flex w-max min-w-max items-center">
                    <div className="flex items-center gap-1 border-0 bg-transparent">
                      <span
                        className={`cursor-pointer w-2 h-2 rounded-full transition ${
                          currentCardIndex === 0 ? "bg-blue-600" : "bg-gray-800"
                        } border-none`}
                      />
                      <span
                        className={`cursor-pointer w-2 h-2 rounded-full transition ${
                          currentCardIndex === 1 ? "bg-blue-600" : "bg-gray-800"
                        } border-none`}
                      />
                      <span
                        className={`cursor-pointer w-2 h-2 rounded-full transition ${
                          currentCardIndex === 2 ? "bg-blue-600" : "bg-gray-800"
                        } border-none`}
                      />
                      <span
                        className={`cursor-pointer w-2 h-2 rounded-full transition ${
                          currentCardIndex === 3 ? "bg-blue-600" : "bg-gray-800"
                        } border-none`}
                      />
                    </div>
                  </div>
                  <button
                    aria-label="next"
                    onClick={handleNext}
                    className="outline-none text-gray-700 transition hover:text-blue-600  p-2 md:p-2.5 hover:bg-blue-100 rounded-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
