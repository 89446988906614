import React from "react";
import smart_chaos_video from "../../Assests/img/video.mp4";

export default function Working() {
  return (
    <div className="bg-black">
      <div className="flex justify-center pt-10">
        <h1 className="text-white font-semibold text-xl md:text-5xl lg:text-5xl xl:text-5xl">
          How SmartChaosLab Works?
        </h1>
      </div>
      <hr className="ml-10 mr-10 md:ml-60 md:mr-60 lg:ml-[570px] lg:mr-[570px] mt-4 border-2 border-[#452283]" />
      <div className="px-4 py-5 md:py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col items-center justify-between lg:flex-row">
          <div className=" lg:max-w-lg lg:pr-5 lg:mb-0">
            <div className="max-w-xl mb-6">
              <p className="text-base text-gray-300 md:text-lg">
                From game theory-driven insights that offer a strategic
                perspective on your systems to AI-powered recommendations that
                provide personalized insights, SmartChaosLab equips you with the
                capabilities to ensure your systems are resilient in the face of
                real-world challenges.
              </p>
              <br />
              <p className="text-base text-gray-300 md:text-lg">
                Our platform simplifies the complexities of chaos engineering,
                making it accessible to both newcomers and seasoned
                practitioners. With SmartChaosLab, you can take control of your
                system's reliability and performance, ultimately delivering a
                more robust and resilient digital ecosystem for your
                organization.
              </p>
            </div>
          </div>
          <div className="relative lg:w-1/2">
            <video
              autoPlay
              muted
              loop
              className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
            >
              <source src={smart_chaos_video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}
