import React from 'react'
import Scenes from './Scenes'

export default function index() {
  return (
    <div>
        <Scenes/>
    </div>
  )
}
