import React from 'react'
import Pricing from './Pricing'

export default function index() {
  return (
    <div>
        <Pricing/>
    </div>
  )
}
