import React, { useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import bg_cover_img from "../../Assests/img/AI_genrated.png";
import Modal from "../Modal/Modal";

export default function HeroSection() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <section className="bg-black">
      <div className="px-4 md:py-12 mx-auto max-w-8xl sm:px-6 md:px-12 lg:px-0 lg:py-14">
        <div className="flex flex-wrap items-center">
          <div className="w-full lg:max-w-lg lg:w-1/2 rounded-xl">
            <div>
              <div className="relative w-full max-w-lg">
                <div className="absolute top-0 rounded-full bg-violet-300 -left-4 w-72 h-72 mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
                <div className="absolute rounded-full bg-fuchsia-300 -bottom-24 right-20 w-72 h-72 mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
                <div className="relative">
                  <img
                    className="mt-5 lg:max-w-[785px] md:max-w-2xl sm:max-w-lg max-w-[290px] object-cover object-center mx-auto rounded-lg shadow-2xl w-70percent"
                    alt="hero"
                    src={bg_cover_img}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start mt-5 mb-16 text-right lg:flex-grow lg:w-1/2 xl:pl-80 md:mb-0 xl:mt-0">
            <h1 className="lg:mb-5 text-4xl text-left mr-2 font-bold leading-none tracking-tighter text-white pb-2 md:pb-2 lg:pb-2 xl:pb-2 md:text-6xl lg:text-5xl">
              Revolutionizing System Reliability with Chaos Engineering.
            </h1>
            <p className="lg:mb-2 mr-2 text-left mt-5 mb-5 md:mt-5 md:mb-5 text-base tracking-tighter leading-relaxed text-gray-200">
              Combines the power of chaos engineering with cutting-edge
              technology to empower you in optimizing system reliability and
              performance.
            </p>
            <div className="mt-0 lg:mt-6 max-w-7xl sm:flex flex justify-center">
              <div className="mt-3 rounded-lg sm:mt-0">
                <Link to={"/key-offerings"}>
                  <button className="items-center mr-2 block px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-800 rounded-xl hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    Explore More
                  </button>
                </Link>
              </div>
              <div className="mt-3 rounded-lg sm:mt-0 sm:ml-3">
                <button
                  onClick={openModal}
                  className="items-center block px-10 py-3.5 text-base font-medium text-center text-white transition duration-500 ease-in-out transform border-2 border-white shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  Get Started
                </button>
              </div>
            </div>
            {/* Modal */}
            <Modal isOpen={isModalOpen} closeModal={closeModal} />
          </div>
        </div>
      </div>
    </section>
  );
}
